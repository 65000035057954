
<template>
  <c-box
    v-chakra="{
      ':hover': {
        backgroundColor: '#F2F2F2'
      },
    }"
    box-shadow="4px 4px 10px 0px rgba(0, 0, 0, 0.15)"
    border-radius="12px"
    padding="20px"
    display="flex"
    align-items="center"
    justify-content="space-between"
    gap="20px"
    :cursor="_hasOnClick ? 'pointer' : 'default'"
    transition-duration="300ms"
    @click="onClick"
  >
    <c-box
      min-width="50px"
    >
      <c-image
        object-fit="cover"
        size="50px"
        :src="icon"
        :alt="label"
      />
      <!--        :src="detail.user_detail.photo"-->
    </c-box>
    <c-box
      flex="1"
    >
      <BaseText
        size-mobile="16px"
        size-desktop="18px"
      >
        {{ label }}
      </BaseText>
    </c-box>
    <c-box
      v-if="_hasOnButtonClick"
    >
      <BaseButton
        h="36px"
        font-size="16px"
        font-weight="400"
        @click.stop="onButtonClick"
      >
        {{ buttonLabel }}
      </BaseButton>
    </c-box>
  </c-box>
</template>

<script>
import BaseButton from '@/components/elements/base-button.vue'
import BaseText from '@/components/elements/base-text.vue'

export default {
  name: 'SimpleCard',
  components: {
    BaseText,
    BaseButton,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    buttonLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    _hasOnClick() {
      return !!this?.$listeners?.['on-click']
    },
    _hasOnButtonClick() {
      return !!this?.$listeners?.['on-button-click'] && !!this.buttonLabel
    },
  },
  methods: {
    onClick() {
      this.$emit('on-click')
    },
    onButtonClick() {
      this.$emit('on-button-click')
    },
  },
}
</script>
