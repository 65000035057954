var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "margin-bottom": "20px",
      "paths": [{
        label: 'Manajemen Klien',
        href: {
          name: 'admin.clients'
        }
      }, {
        label: 'Detail Klien',
        href: {
          name: 'admin.clients-detail',
          query: _vm.$route.query
        }
      }, {
        label: 'Detail Program',
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "width": "100%",
      "max-width": "1270px",
      "background-color": "#FFF",
      "margin-bottom": "16px",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['16px', '30px'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "30px",
      "padding": "0 40px"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "20px",
      "size-desktop": "28px"
    }
  }, [_vm._v(" Detail Program ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" " + _vm._s(_vm.program.name) + " ")])], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Data Pribadi ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "30px",
      "pos": "relative"
    }
  }, [_c('c-box', [_c('c-image', {
    attrs: {
      "border-radius": "100% ",
      "object-fit": "cover",
      "size": "150px",
      "src": _vm.getPhotoUser(_vm.user.photoUrl),
      "alt": _vm.user.fullName
    }
  })], 1), _c('c-box', {
    attrs: {
      "width": "100%",
      "flex": "1"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Nama ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.user.fullName) + " ")]), _c('BaseText', {
    attrs: {
      "margin-top": "24px",
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Program ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.program.name) + " (" + _vm._s(_vm.program.programService) + ") ")]), !_vm.openSetEndDate ? _c('c-box', [_c('BaseText', {
    attrs: {
      "margin-top": "24px",
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Join Date - End Date ")]), _c('c-flex', {
    attrs: {
      "align-items": "center"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDateV2(_vm.program.startAt)) + " - " + _vm._s(_vm.formatDateV2(_vm.program.endAt)) + " ")]), _vm.isSetEndDate ? _c('c-button', {
    attrs: {
      "variant": "link",
      "d": "flex",
      "align-items": "center",
      "ml": "20px",
      "color": "primary.400"
    },
    on: {
      "click": function click($event) {
        _vm.openSetEndDate = true;
      }
    }
  }, [_c('c-box', {
    attrs: {
      "mr": "5px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-edit.svg'),
      "height": "18px",
      "width": "18px",
      "fill": "#008C81"
    }
  })], 1), _vm._v(" Edit ")], 1) : _vm._e()], 1)], 1) : _c('c-flex', {
    attrs: {
      "gap": "1rem"
    }
  }, [_c('c-box', [_c('BaseText', {
    attrs: {
      "margin-top": "24px",
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Join Date ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px",
      "mt": "12px"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDateV2(_vm.program.startAt)) + " ")])], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "margin-top": "24px",
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" End Date ")]), _c('c-flex', {
    attrs: {
      "gap": "8px"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "masks": _vm.masks,
      "min-date": new Date(_vm.minEndDate),
      "model-config": _vm.modelConfig,
      "mode": "date"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var inputValue = _ref.inputValue,
            togglePopover = _ref.togglePopover;
        return [_c('c-flex', {
          attrs: {
            "w": "100%",
            "pos": "relative",
            "align-items": "center"
          }
        }, [_c('c-box', {
          attrs: {
            "pos": "absolute",
            "left": "0px",
            "z-index": "2",
            "w": "3rem"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-date.svg'),
            "height": "25",
            "width": "100%",
            "fill": "#008C81"
          }
        })], 1), _c('c-input', {
          attrs: {
            "value": inputValue,
            "h": ['48px', '50px'],
            "w": "230px",
            "border-type": "solid",
            "border-width": "1px",
            "border-color": "#888",
            "border-radius": "8px",
            "pl": "3rem",
            "pr": "0px",
            "font-family": "Roboto",
            "font-size": ['14px', '18px'],
            "font-weight": "500",
            "color": "#008C81",
            "readonly": ""
          },
          on: {
            "focus": function focus() {
              togglePopover();
            }
          }
        })], 1)];
      }
    }]),
    model: {
      value: _vm.endDate,
      callback: function callback($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  }), _c('c-button', {
    attrs: {
      "variant": "solid",
      "h": ['48px', '50px'],
      "font-size": ['14px', '18px'],
      "variant-color": "primary",
      "border-radius": "1.5rem",
      "px": "1.5rem"
    },
    on: {
      "click": _vm.onOpenConfirm
    }
  }, [_vm._v(" Simpan ")])], 1)], 1)], 1), _c('BaseText', {
    attrs: {
      "margin-top": "24px",
      "size-mobile": "14px-2",
      "size-desktop": "16px"
    }
  }, [_vm._v(" Durasi ")]), _c('BaseText', {
    attrs: {
      "size-mobile": "16px",
      "size-desktop": "18px"
    }
  }, [_vm._v(" " + _vm._s(_vm.program.duration) + " Hari ")])], 1), _c('c-box', {
    attrs: {
      "pos": "absolute",
      "top": "0px",
      "right": "0px"
    }
  }, [_c('BaseText', {
    attrs: {
      "size-mobile": "14px-2",
      "size-desktop": "16px",
      "color": "#008C81"
    }
  }, [_vm._v(" Counter day: " + _vm._s(_vm.program.counterDay) + " ")])], 1)], 1)], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Ahli Gizi yang Dipilih ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _vm.nutritionist ? _c('CardNutritionist', {
    attrs: {
      "is-disabled-change-nutritionist": !_vm.isAbleToChangeNutri,
      "name": _vm.getFullnameAndTitle(_vm.nutritionist.fullName, _vm.nutritionist.education),
      "photo": _vm.nutritionist.photoUrl,
      "service-hours": _vm.nutritionist.serviceHour,
      "ratings": _vm.nutritionist.rating,
      "educations": _vm.educationsParser(_vm.nutritionist.education),
      "specializations": _vm.nutritionist.specialization,
      "remaining-quota": _vm.nutritionist.remainingQuota
    },
    on: {
      "on-change-nutritionist": _vm.handleChangeNutritionist
    }
  }) : _vm._e()], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Diet Data ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "grid",
      "grid-template-columns": "repeat(2, minmax(0, 1fr))",
      "grid-gap": "30px"
    }
  }, _vm._l(_vm.LIST_MENU_DIET_DATA, function (menu) {
    return _c('SimpleCard', {
      key: menu.label,
      attrs: {
        "icon": menu.icon,
        "label": menu.label,
        "button-label": menu.buttonLabel
      },
      on: {
        "on-button-click": function onButtonClick($event) {
          menu.onButtonClick && _vm.routerPush(menu.onButtonClick);
        },
        "on-click": function onClick($event) {
          return _vm.routerPush(menu.onClick);
        }
      }
    });
  }), 1)], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jadwal Panduan Makan ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "grid",
      "grid-template-columns": "repeat(2, minmax(0, 1fr))",
      "grid-gap": "30px"
    }
  }, _vm._l(_vm.meal_plan_schedule, function (v, i) {
    return _c('SimpleCard2', {
      key: v.id + i,
      attrs: {
        "title": v.title,
        "description": v.date,
        "status": v.status
      }
    });
  }), 1)], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jadwal ADIME Notes & Indikator Progress and Tracker ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "grid",
      "grid-template-columns": "repeat(2, minmax(0, 1fr))",
      "grid-gap": "30px"
    }
  }, _vm._l(_vm.adime_notes_and_indicator_progress_and_tracker_schedule, function (v, i) {
    return _c('SimpleCard2', {
      key: v.id + i,
      attrs: {
        "title": v.title,
        "description": v.date,
        "status": v.status
      }
    });
  }), 1)], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jadwal Follow Up Notes ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "grid",
      "grid-template-columns": "repeat(2, minmax(0, 1fr))",
      "grid-gap": "30px"
    }
  }, _vm._l(_vm.follow_up_notes_schedule, function (v, i) {
    return _c('SimpleCard2', {
      key: v.id + i,
      attrs: {
        "title": v.title,
        "description": v.date,
        "status": v.status
      }
    });
  }), 1)], 1), _c('c-box', [_c('BaseText', {
    attrs: {
      "size-mobile": "18px",
      "size-desktop": "20px",
      "color": "#008C81"
    }
  }, [_vm._v(" Jadwal Progress & Tracker Feedback ")]), _c('BaseDivider', {
    attrs: {
      "margin": "10px 0 30px 0"
    }
  }), _c('c-box', {
    attrs: {
      "display": "grid",
      "grid-template-columns": "repeat(2, minmax(0, 1fr))",
      "grid-gap": "30px"
    }
  }, _vm._l(_vm.progress_and_tracker_feedback_schedule, function (v, i) {
    return _c('SimpleCard2', {
      key: v.id + i,
      attrs: {
        "title": v.title,
        "description": v.date,
        "status": v.status
      }
    });
  }), 1)], 1)], 1)], 1), _c('ModalConfirm', {
    attrs: {
      "title": "Apakah anda yakin ingin mengubah data end date dari ".concat(_vm.formatDateV2(_vm.program.endAt), " menjadi ").concat(_vm.formatDateV2(_vm.endDate), "?"),
      "image": "https://ik.imagekit.io/dietela/pwa_webp/popup/apakah_yakin.png?updatedAt=1690890254662",
      "is-open": _vm.isOpenConfirmSetEndDate,
      "is-loading": _vm.isLoadingSetEndDate,
      "submit-text": "Simpan"
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": _vm.onSubmitConfirm
    }
  }), _c('ModalSuccess', {
    attrs: {
      "title": "Data end date berhasil diubah!",
      "note": "",
      "image": require('@/assets/paid.png'),
      "is-open": _vm.isSuccess,
      "button-text": "Oke"
    },
    on: {
      "close": _vm.onCloseSuccess
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }